import { useState, useReducer, useCallback } from "react";
import { AUTH_PATH } from "./env";
import { notification } from "antd";
import OTPInput from "./OTPInput/Index";
type Action =
  | { type: "email_input_changed"; value: string }
  | { type: "otp_changed"; value: string }
  | { type: "sending_email" }
  | { type: "verify_otp" }
  | { type: "verify_otp_error" }
  | { type: "reference_id_received"; ref: string }
  | { type: "send_error" }
  | { type: "set_is_register" }
  | { type: "submit_otp" };
interface State {
  email: string;
  displayState: DisplayState;
  otp: string;
  refId: string;
  isLogin: boolean;
}
type Props = {
  onSuccess: () => void;
};

export enum DisplayState {
  Idle = "Idle",
  Sending = "Sending",
  Otp = "Otp",
  VerifyOTP = "VerifyOTP",
  VerifyOTPError = "VerifyOTPError",
}
function reducer(state: State, action: Action) {
  switch (action.type) {
    case "email_input_changed":
      return { ...state, email: action.value };
    case "send_error":
      return { ...state, displayState: DisplayState.Idle };
    case "reference_id_received":
      return { ...state, refId: action.ref };
    case "sending_email":
      return { ...state, displayState: DisplayState.Sending };
    case "verify_otp":
      return { ...state, displayState: DisplayState.Otp };
    case "verify_otp_error":
      return { ...state, displayState: DisplayState.VerifyOTPError };
    case "otp_changed":
      return { ...state, otp: action.value };
    case "submit_otp":
      return { ...state, displayState: DisplayState.VerifyOTP };
    case "set_is_register":
      console.log("change to regis");
      return { ...state, isLogin: false };
    default:
      throw new Error("error");
  }
}
function Login(props: Props) {
  const [state, dispatch] = useReducer(reducer, {
    email: "",
    displayState: DisplayState.Idle,
    otp: "",
    refId: "",
    isLogin: true,
  });
  const onEmailInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: "email_input_changed", value: e.target.value });
    // if (emailRef.current) setIsEmailValid(emailRef.current.validity.valid);
  }, []);
  const NotifyError = () => {
    console.log("error");
    notification["error"]({
      message: "",
      description: "Error",
    });
  };
  const verifyOTP = async (otp: string, isLogin: boolean) => {
    dispatch({ type: "submit_otp" });
    console.log("ref", state.refId);
    if (isLogin) {
      const result = await fetch(`${AUTH_PATH}/email/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: state.email,
          otp_value: otp,
          otp_ref_id: state.refId,
        }),
      });
      const json = await result.json().catch((e) => {
        console.error(e);
      });
      if (result.status === 200) {
        localStorage.setItem("AuthJWT", json.jwt);
        props.onSuccess();
      } else {
        await new Promise((f) => setTimeout(f, 1000));
        dispatch({ type: "verify_otp_error" });
      }
    } else {
      const result = await fetch(`${AUTH_PATH}/email/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: state.email,
          otp_value: otp,
          otp_ref_id: state.refId,
        }),
      });
      const json = await result.json().catch((e) => {
        console.error(e);
      });
      if (result.status === 200) {
        localStorage.setItem("AuthJWT", json.jwt);
        props.onSuccess();
      } else {
        await new Promise((f) => setTimeout(f, 1000));
        dispatch({ type: "verify_otp_error" });
        // failed dk what to do 0
      }
    }
  };
  const onOTPInput = useCallback(
    async (otpInput: string) => {
      console.log("otp Input", otpInput);
      dispatch({ type: "otp_changed", value: otpInput });
      if (otpInput.length === 6) {
        await verifyOTP(otpInput, state.isLogin);
      }
      // send({ type: "login__otp_submitted", otp: otpInput });
    },
    [dispatch, state]
  );
  const onOTPpaste = useCallback(
    async (otpPaste: string) => {
      dispatch({ type: "otp_changed", value: otpPaste });
      if (otpPaste.length === 6) {
        await verifyOTP(otpPaste, state.isLogin);
      }
    },
    [dispatch, state]
  );
  const onEmailSubmitted = useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();
      dispatch({ type: "sending_email" });
      try {
        const result = await fetch(`${AUTH_PATH}/email/request-login-otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: state.email,
          }),
        });
        const json = await result.json();
        console.log("json", json);
        await new Promise((f) => setTimeout(f, 500));
        if (result.status === 200) {
          dispatch({ type: "verify_otp" });
          dispatch({ type: "reference_id_received", ref: json.ref_id });
        } else {
          throw new Error("");
        }
      } catch (e) {
        const result = await fetch(`${AUTH_PATH}/email/request-register-otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: state.email,
          }),
        });
        const json = await result.json();
        console.log("json", json);
        await new Promise((f) => setTimeout(f, 1000));
        if (result.status === 200) {
          dispatch({ type: "set_is_register" });
          dispatch({ type: "verify_otp" });
          dispatch({ type: "reference_id_received", ref: json.ref_id });
        } else {
          NotifyError();
          dispatch({ type: "send_error" });
        }
      }
    },
    [state.email, state.displayState]
  );

  return (
    <div className="page min-h-screen w-full flex flex-col items-center justify-center px-14">
      <div className="w-full md:w-1/3 bg-white p-8 rounded-md bg-gray-50">
        {[
          DisplayState.Otp,
          DisplayState.VerifyOTP,
          DisplayState.VerifyOTPError,
        ].includes(state.displayState) && (
          <div className="items-center">
            <>
              We have sent 6-digits verification code to your email{" "}
              <span className="text-blue-600">{state.email}</span> ( ref code:
              <span className="text-red-500">
                {state.refId ? state.refId : "----"}
              </span>
              ), please check & fill in the code here.
            </>
            <OTPInput
              autoFocus
              isNumberInput
              length={6}
              className="mt-4 space-x-2 sm:space-x-4 flex flex-row items-center justify-center"
              onChangeOTP={onOTPInput}
              onPasteOTP={onOTPpaste}
              disabled={state.displayState === DisplayState.VerifyOTP}
            ></OTPInput>
          </div>
        )}
        {[DisplayState.VerifyOTP].includes(state.displayState) && (
          <div className="w-full flex items-center justify-center mt-4 text-gray-500">
            Verifying...
          </div>
        )}
        {[DisplayState.VerifyOTPError].includes(state.displayState) && (
          <div className="w-full flex items-center justify-center mt-4 text-red-500">
            Invalid OTP
          </div>
        )}
        {[DisplayState.Idle, DisplayState.Sending].includes(
          state.displayState
        ) && (
          <form
            autoComplete="off"
            onSubmit={onEmailSubmitted}
            className="flex flex-col h-20"
            name="basic"
          >
            <input
              className="block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
              type="email"
              placeholder="example@biggestfan.net"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,}$"
              required
              disabled={state.displayState != DisplayState.Idle}
              onInput={onEmailInput}
            />
            {state.displayState === DisplayState.Idle && (
              <button
                className="bg-blue-500 text-white mt-2 py-1 rounded-md ml-auto w-20 disabled:bg-gray-200 disabled:text-gray-400"
                type="submit"
                disabled={
                  state.email == "" || state.displayState != DisplayState.Idle
                }
                value="submit"
              >
                Submit
              </button>
            )}
            {state.displayState === DisplayState.Sending && (
              <button
                className="bg-blue-500 text-white mt-2 py-1 rounded-md ml-auto w-20 disabled:bg-gray-200 disabled:text-gray-400"
                disabled
              >
                Sending...
              </button>
            )}
          </form>
        )}
      </div>
    </div>
  );
}

export default Login;
