import { useEffect, useState } from "react";
import Tournament from "./Tournament";
import Login from "./Login";
// import { parseJwt } from "./helpers/jwtParser";

enum DisplayState {
  Loading,
  Login,
  Idle,
}
const parseJwt = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const buff = Buffer.from(base64, "base64");
  const payloadinit = buff.toString("ascii");
  return JSON.parse(payloadinit);
};
function Main() {
  const [displayState, setDisplayState] = useState(DisplayState.Login);
  function checkLogin() {
    const jwt = localStorage.getItem("AuthJWT");
    if (jwt) {
      const body = JSON.parse(atob(jwt.split(".")[1]));
      const expired = body.exp;
      const now = new Date();
      const isExpired = new Date(expired * 1000) < now;
      if (isExpired) return false;
      return true;
    }

    return false;
  }
  function LoginSuccess() {
    setDisplayState(DisplayState.Idle);
  }
  useEffect(() => {
    console.log("activate use effect");
    if (checkLogin()) {
      setDisplayState(DisplayState.Idle);
    } else {
      setDisplayState(DisplayState.Login);
    }
    console.log("x");
  }, [DisplayState]);
  return displayState === DisplayState.Idle ? (
    <Tournament />
  ) : (
    <Login onSuccess={LoginSuccess} />
  );
}

function App() {
  return <Main />;
}
export default App;
